<template>
	<b-card>
		<div>
			<b-form ref="form" class="repeater-form" @submit.prevent="repeateAgain">
				<!-- Row Loop -->
				<h4 class="mb-1">Color</h4>
				<b-row v-for="item in items" :id="item.id" :key="item.id" ref="row">
					<!-- Item Name -->

					<b-col md="1">
						<b-form-input id="item-name" type="color" value="#f6b73c" />
					</b-col>
					<b-col md="1">
						<b-form-input id="item-name" type="color" value="#FD78A1" />
					</b-col>
					<b-col md="1">
						<b-form-input id="item-name" type="color" value="#249CF4" />
					</b-col>

					<!-- Remove Button -->
					<!-- <b-col lg="2" md="3" class="">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              type="color"
              @click="removeItem(index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
              <span>Delete</span>
            </b-button>
          </b-col> -->
					<!-- <b-col cols="12">
            <hr />
          </b-col> -->
				</b-row>
				<b-col cols="12">
					<hr />
				</b-col>
			</b-form>
			<!-- <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="repeateAgain"
      >
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>Add New</span>
      </b-button> -->
			<b-form-input
				type="color"
				id="my-color"
				style="opacity: 0; visibility: hidden; position: absolute"
			/>

			<label for="my-color" class="AddNew">
				<feather-icon icon="PlusIcon" class="mr-25" />Add New</label
			>
		</div>
		<div>
			<validation-observer ref="simpleRules">
				<div
					class="demo-inline-spacing d-flex justify-content-end align-items-center"
				>
					<div>Fahrenheit</div>
					<b-form-checkbox
						checked="true"
						class="custom-control-primary"
						name="check-button"
						switch
					>
						<span class="switch-icon-left">
							<feather-icon icon="BellIcon" />
						</span>
						<span class="switch-icon-right">
							<feather-icon icon="BellOffIcon" />
						</span>
					</b-form-checkbox>
					<div>Celsius</div>
					<b-form-checkbox
						checked="true"
						class="custom-control-primary"
						name="check-button"
						switch
					>
						<span class="switch-icon-left">
							<feather-icon icon="CheckIcon" />
						</span>
						<span class="switch-icon-right">
							<feather-icon icon="XIcon" />
						</span>
					</b-form-checkbox>
				</div>

				<h2 class="mb-2">Setter</h2>
				<h4 class="mb-1 mt-2">Set Temperature</h4>
				<b-row>
					<b-col>
						<!-- <b-form-group label="Minimum " label-for="Minimum">
							<b-form-input id="basicInput" placeholder="" />
						</b-form-group> -->
						<b-form-group
							label="Enter Vaccination Name"
							label-for="Enter Vaccination Name"
						>
							<validation-provider
								#default="{ errors }"
								name="Vaccination Name"
								rules="required"
							>
								<b-form-input
									name="vaccination_name"
									v-model="vaccinationScheduleData.name"
									placeholder="Enter Vaccination Name"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="Maximum" label-for="Maximum">
							<b-form-input id="basicInput" placeholder="" />
						</b-form-group>
					</b-col>
				</b-row>
				<h4 class="mb-1 mt-2">Set Humidity</h4>
				<b-row>
					<b-col>
						<b-form-group label="Minimum " label-for="Minimum">
							<b-form-input id="basicInput" placeholder="" />
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="Maximum" label-for="Maximum">
							<b-form-input id="basicInput" placeholder="" />
						</b-form-group>
					</b-col>
				</b-row>
				<h2 class="mb-2 mt-2">Hatcher</h2>
				<h4 class="mb-1 mt-2">Set Temperature</h4>
				<b-row>
					<b-col>
						<b-form-group label="Minimum " label-for="Minimum">
							<b-form-input id="basicInput" placeholder="" />
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="Maximum" label-for="Maximum">
							<b-form-input id="basicInput" placeholder="" />
						</b-form-group>
					</b-col>
				</b-row>
				<h4 class="mb-1 mt-2">Set Humidity</h4>
				<b-row>
					<b-col>
						<b-form-group label="Minimum " label-for="Minimum">
							<b-form-input id="basicInput" placeholder="" />
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="Maximum" label-for="Maximum">
							<b-form-input id="basicInput" placeholder="" />
						</b-form-group>
					</b-col>
				</b-row>
			</validation-observer>
		</div>
		<b-button
			v-ripple.400="'rgba(255, 255, 255, 0.15)'"
			type="submit"
			variant="success"
			class="mr-1"
		>
			Submit
		</b-button>
	</b-card>
</template>

<script>
import {
	BButton,
	BForm,
	BFormGroup,
	BFormInput,
	BRow,
	BCol,
	BCard,
	BInputGroup,
	BInputGroupAppend,
	BFormCheckboxGroup,
	BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axiosIns from "@/libs/axios";
import moment from "moment";
import { required, email, min_value, max_value, integer } from "@validations";
import { heightTransition } from "@core/mixins/ui/transition";
export default {
	components: {
		BButton,
		BForm,
		BFormGroup,
		BFormInput,
		BRow,
		BCol,
		BCard,
		BInputGroup,
		BInputGroupAppend,
		BFormCheckbox,
		BFormCheckboxGroup,
	},
	directives: {
		Ripple,
	},
	mixins: [heightTransition],
	data() {
		return {
			items: [
				{
					id: 1,
					selected: "male",
					selected1: "designer",
					prevHeight: 0,
				},
			],
			nextTodoId: 2,
		};
	},
	mounted() {
		this.initTrHeight();
	},
	created() {
		window.addEventListener("resize", this.initTrHeight);
	},
	destroyed() {
		window.removeEventListener("resize", this.initTrHeight);
	},
	methods: {
		repeateAgain() {
			this.items.push({
				id: (this.nextTodoId += this.nextTodoId),
			});

			this.$nextTick(() => {
				this.trAddHeight(this.$refs.row[0].offsetHeight);
			});
		},
		removeItem(index) {
			this.items.splice(index, 1);
			this.trTrimHeight(this.$refs.row[0].offsetHeight);
		},
		initTrHeight() {
			this.trSetHeight(null);
			this.$nextTick(() => {
				this.trSetHeight(this.$refs.form.scrollHeight);
			});
		},
		addHatcherySetting(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					var data = new FormData();
					data.append("vaccine_name", this.vaccine_name);
					data.append("unit", this.selectedUnit);
					data.append("route", this.selectedRoute);
					data.append("expiry_date", this.value);
					data.append("company_id", this.$route.params.companyId);
					axiosIns
						.post(`web/storeVaccine`, data)
						.then((res) => {
							res;
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `data Successfully Added `,
								},
							});
							this.$refs["my-modal"].hide();
							this.getVaccineList();
						})
						.catch((error) => {
							error.response;
							const data = error.response.data.errors;
							data, "data";
							let arr = Object.values(data).flat().toString(",");

							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},
	},
};
</script>
<style scoped>
.AddNew {
	border: 1px solid transparent;
	padding: 0.786rem 1.5rem;
	border-radius: 0.358rem;
	background-color: #296db4;
	color: #fff;
}
</style>
